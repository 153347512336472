<ng-container *ngIf="viewModel.subSignUpFlow === Types.SubAuthFlow.EmailVerification">
  <app-form-group
    #emailVerificationForm
    class="d-block pt-4"
    *ngIf="emailVerificationFormItem?.length > 0"
    [formItems]="emailVerificationFormItem"
    [formObject]="emailVerificationFormObject"
    [styling]="emailVerificationFormStyling"
    [options]="emailVerificationFormOptions"
    (formSubmitted)="continueToNextSignUpStep()">
  </app-form-group>
  <span *ngIf="emailVerificationButtonClicked" class="email-verification-message">{{verificationDisplayMessage()}}</span>
</ng-container>
<ng-container *ngIf="viewModel.subSignUpFlow === Types.SubAuthFlow.EmailVerificationLinkExisted">
  <app-form-group
    #emailVerificationLinkExisted
    class="d-block pt-4"
    *ngIf="emailVerificationFormItem?.length > 0"
    [formItems]="emailVerificationFormItem"
    [formObject]="emailVerificationFormObject"
    [styling]="emailVerificationFormStyling"
    [options]="emailVerificationFormOptions">
  </app-form-group>
  <span *ngIf="emailVerificationButtonClicked || displayUserExistsMessage" class="email-verification-message">{{verificationDisplayMessage()}}</span>
</ng-container>
<ng-container *ngIf="viewModel.subSignUpFlow === Types.SubAuthFlow.EmailVerificationLinkExpired">
  <app-form-group
    #emailVerificationExpired
    class="d-block pt-4"
    *ngIf="emailVerificationFormItem?.length > 0"
    [formItems]="emailVerificationFormItem"
    [formObject]="emailVerificationFormObject"
    [styling]="emailVerificationFormStyling"
    [options]="emailVerificationFormOptions"
    (formSubmitted)="continueToNextSignUpStep()">
  </app-form-group>
  <span *ngIf="!emailVerificationButtonClicked" class="email-verification-message">{{verificationDisplayMessage()}}</span>
  <span *ngIf="emailVerificationButtonClicked" class="email-verification-message">{{emailVerificationMessage}}</span>
</ng-container>
<ng-container *ngIf="viewModel.subSignUpFlow === Types.SubAuthFlow.EmailVerificationUnverified">
  <app-form-group
    #emailVerificationUnverified
    class="d-block pt-4"
    [formObject]="emailVerificationFormObject"
    (formSubmitted)="continueToNextSignUpStep()">
  </app-form-group>
  <div *ngIf="emailVerificationButtonClicked" class="email-verification-message">{{verificationDisplayMessage()}}</div>
</ng-container>
<ng-container *ngIf="viewModel.subSignUpFlow === Types.SubAuthFlow.EmailVerificationInvalidLink">
  <app-form-group
    [formObject]=""
    (formSubmitted)="continueToNextSignUpStep()">
  </app-form-group>
  <span *ngIf="viewModel.subSignUpFlow === Types.SubAuthFlow.EmailVerificationInvalidLink" class="email-verification-message">{{verificationDisplayMessage()}}</span>
</ng-container>
<ng-container *ngIf="viewModel.subSignUpFlow === Types.SubAuthFlow.CreateProfile">
  <app-form-group
    #userProfileForm
    class="d-block pt-4"
    *ngIf="userProfileFormItems?.length > 0"
    [formItems]="userProfileFormItems"
    [formObject]="userProfileFormObject"
    [styling]="userProfileFormStyling"
    [options]="userProfileFormOptions"
    (formSubmitted)="continueToNextSignUpStep()">
  </app-form-group>
  <div [ngClass]="{'error-text': passwordErrorMessage === 'Passwords do not match', 'success-message': passwordErrorMessage === 'Passwords match'}">{{this.passwordErrorMessage}}</div>
</ng-container>
<ng-container *ngIf="viewModel.subSignUpFlow === Types.SubAuthFlow.PickPlan">
  <div
    class="custom-alert custom-alert-danger mt-4"
    *ngIf="pickPlanError">
    <img
      class="custom-alert-icon"
      [src]="'assets/icons/light/solid/x-circle.svg'" alt="">
    {{pickPlanError}}
  </div>
  <div class="plan-container">
    <app-radiobutton-container
      [isBoxed]="false"
    (changed)="onRadioButtonGroupChange($event)"
    [radioContainerOptions]="radioButtonContainerOptions"
    [radiobuttons]="planRadioButtons"
    >
    </app-radiobutton-container>
  </div>
</ng-container>

<ng-container *ngIf="viewModel.subSignUpFlow === Types.SubAuthFlow.ProfileDetails">
  <div
    class="custom-alert custom-alert-danger mt-4"
    *ngIf="profileDetailsError">
    <img
      class="custom-alert-icon"
      [src]="'assets/icons/light/solid/x-circle.svg'" alt="">
    {{profileDetailsError}}
  </div>
  <app-form-group
  #profileDetailsForm
  class="d-block pt-4"
  *ngIf="userProfileDetailsFormItems?.length > 0"
  [formItems]="userProfileDetailsFormItems"
  [formObject]="userProfileDetailsFormObject"
  [styling]="userProfileDetailsFormStyling"
  [options]="userProfileDetailsFormOptions"
  (formSubmitted)="continueToNextSignUpStep()">
  </app-form-group>
</ng-container>

<ng-container *ngIf="viewModel.subSignUpFlow === Types.SubAuthFlow.PickPlanPackage">
  <div
    class="custom-alert custom-alert-danger mt-4"
    *ngIf="pickPlanError">
    <img
      class="custom-alert-icon"
      [src]="'assets/icons/light/solid/x-circle.svg'" alt="">
    {{pickPlanError}}
  </div>
  <div class="plan-container">
    <div class="plan-package-label">{{displayLabel()}}</div>
    <app-radiobutton-container
      [isBoxed]="true"
      (changed)="selectedPricingOptionsChanged()"
      [radioContainerOptions]="radioButtonContainerOptions"
      [radiobuttons]="pricingOptionCheckboxes">
    </app-radiobutton-container>
    <div class="plan-description-container">
      <div class="payment-duration-description-box">{{mostPopularText()}}{{paymentDurationDescription()}}</div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="viewModel.subSignUpFlow === Types.SubAuthFlow.ChooseOrganizationToSupport">
  <div class="mt-4">
    <h6>Which league or organization do you plan to watch?</h6>
    <app-drop-down
      (selection)="selectedLeagueChanged($event)"
      [currentSelection]="this.viewModel.selectedLeague"
      [dropdowns]="this.viewModel.leagues">
    </app-drop-down>
  </div>
</ng-container>
<!--exculisive for the private hub access-->
<ng-container *ngIf="viewModel.subSignUpFlow === Types.SubAuthFlow.Review
      && viewModel.selectedPlan$.value?.category ===3 ">
     <app-form-group
     #accessCodeForm
     class="d-block pt-3"
     *ngIf="accessCodeFormItems?.length > 0"
     [formItems]="accessCodeFormItems"
     [formObject]="accessCodeFormObject"
     [styling]="accessCodeFormStyling"
     [options]="accessCodeFormOptions">
   </app-form-group>
   <div *ngIf="!!discountCodeError && this.viewModel.discountCodeSuccessMessage === false" class="label-xs red-text discount-code-error">Invalid code contact administrator for valid code</div>
   <div *ngIf="!!discountCodeError && this.viewModel.discountCodeSuccessMessage===true" class="label-xs green-text discount-code-success">Code Applied successfully</div>
   <app-pill
   (click)="viewModel.removeAppliedDiscount(appliedDiscount)"
   [clickable]="true"
   [selected]="true"
   *ngFor="let appliedDiscount of (viewModel.couponsToApply$|async)">
   {{appliedDiscount.name}}
 </app-pill>
</ng-container>
<ng-container *ngIf="viewModel.subSignUpFlow === Types.SubAuthFlow.Review
      && viewModel.selectedPlan$.value?.category !==3 ">
  <app-form-group
    #discountCodeForm
    class="d-block pt-3"
    *ngIf="discountCodeFormItems?.length > 0"
    [formItems]="discountCodeFormItems"
    [formObject]="discountCodeFormObject"
    [styling]="discountCodeFormStyling"
    [options]="discountCodeFormOptions">
  </app-form-group>
  <div *ngIf="!!discountCodeError && this.viewModel.discountCodeSuccessMessage === false" class="label-xs red-text discount-code-error">{{discountCodeError}}</div>
  <div *ngIf="!!discountCodeError && this.viewModel.discountCodeSuccessMessage" class="label-xs green-text discount-code-success">{{discountCodeError}}</div>
  <app-pill
    (click)="viewModel.removeAppliedDiscount(appliedDiscount)"
    [clickable]="true"
    [selected]="false"
    [coupon]="true"
    *ngFor="let appliedDiscount of (viewModel.couponsToApply$|async)">
    {{appliedDiscount.name}}
  </app-pill>
  <button 
    [ngClass]="viewModel.getApplyDiscountButtonClass()"
    [disabled]="viewModel.isApplyDiscountButtonDisabled()"
    (click)="handleDiscountCodeCheck()">
    Apply Discount
  </button>
  <hr class="mt-0">
  <div class="d-flex align-items-center justify-content-between mb-4">
    <div>
      <div class="label-md">{{(viewModel.selectedPlanPricingOptions$|async)?.length === 1 ? 'Plan' : 'Plans'}}</div>
      <div class="label-xs">
        {{viewModel.selectedPlansDescriptionText$|async}}
        <span
          [class.line-through-text]="(viewModel.selectedPlansTotalNoDiscountsText$|async) !== (viewModel.selectedPlansTotalText$|async)">
          {{viewModel.selectedPlansTotalNoDiscountsText$|async}}
        </span>
        <span
          *ngIf="(viewModel.selectedPlansTotalNoDiscountsText$|async) !== (viewModel.selectedPlansTotalText$|async)"
          class="ml-2 red-text">
          {{viewModel.selectedPlansTotalText$|async}}
        </span>
      </div>
    </div>
    <button *ngIf="!viewModel.isEditbuttonDisabled()" class="custom-button secondary-button"
    (click)="editClicked()">Edit</button>
  </div>
  <hr>

  <div *ngFor="let p of (viewModel.selectedPlanPricingOptions$|async)">
    <div class="label-sm">{{p.name}}</div>

    <div class="label-xs color-mid-grey">
      <span
        [class.line-through-text]="viewModel.getTotalTextForPlanLineItem(p, false) !== viewModel.getTotalTextForPlanLineItem(p, true)">
          {{viewModel.getTotalTextForPlanLineItem(p, false)}}
        </span>
      <span
        *ngIf="viewModel.getTotalTextForPlanLineItem(p, false) !== viewModel.getTotalTextForPlanLineItem(p, true)"
        class="ml-2 red-text">
          {{viewModel.getTotalTextForPlanLineItem(p, true)}}
        </span>
    </div>
    <hr>
  </div>
  <div class="invoice-summary-row">
    <div class="paragraph-sm" i18n>Total</div>
    <div class="label-sm">
      {{viewModel.invoiceSummaryInvoiceTotalText$|async}}
    </div>
  </div>
</ng-container>

<ng-container *ngIf="viewModel.subSignUpFlow === Types.SubAuthFlow.AddPaymentDetails">
  <app-form-group
    #paymentDetailsForm
    class="d-block pt-3"
    *ngIf="paymentDetailsFormItems?.length > 0"
    [formItems]="paymentDetailsFormItems"
    [formObject]="paymentDetailsFormObject"
    [styling]="paymentDetailsFormStyling"
    [options]="paymentDetailsFormOptions"
    disabled="this.viewModel.isPaymentProcessing$|async"
    (formSubmitted)="continueToNextSignUpStep()">
  </app-form-group>
</ng-container>
<ng-container *ngIf="viewModel.subSignUpFlow === Types.SubAuthFlow.Completed">
  <div class="sign-up-complete">
    <div class="title-md">Start streaming live events with HomeTeam.</div>
    <div class="paragraph-md mb-auto">
      You’re all set. HomeTeam Live is the best place to watch events for your favourite leagues and teams.
      Get started now or begin setting up your account preferences.
    </div>
    <button class="custom-button secondary-button mr-auto" (click)="continueToNextSignUpStep()">
      <img [src]="'assets/icons/red/outline/play.svg'" alt="">
      Start Watching
    </button>
  </div>
</ng-container>
