import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {SearchDomainModel} from '../../../../domainModels/search-domain-model';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {ResourceDomainModel} from '../../../../domainModels/resource-domain-model';
import {debounceTime, filter, map} from 'rxjs/operators';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {SearchListable} from '../../../../models/protocols/search-listable';
import {indicateOnNext} from '../../../../utils/observable.extensions';
import Fuse from 'fuse.js';
import {SearchListItemContainer} from './search-list-item-container';

@Injectable()
export class SearchModalViewModel extends BaseViewModel {

  loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false);
  searchText$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  searchListItems$: Observable<Fuse<SearchListItemContainer>> = combineLatest([
    this.resourceDomainModel.activeHydratedEvents$,
    this.resourceDomainModel.activeHydratedLeagues$,
    this.resourceDomainModel.activeHydratedVenues$,
    this.resourceDomainModel.activeHydratedTeams$
  ]).pipe(
    filter(results => results.every(r => !!r)),
    indicateOnNext(this.loadingOpts, ''),
    map((results) => {
      const allResults = results.flatMap(r => r ?? []);
      return SearchListItemContainer.generateFuse(allResults);
    })
  );

  searchResults$: Observable<SearchListable[]> = combineLatest([
    this.searchText$,
    this.searchListItems$,
    this.resourceDomainModel.activeHydratedLeagues$,
    this.resourceDomainModel.activeHydratedEvents$,    this.resourceDomainModel.activeHydratedVenues$,
    this.resourceDomainModel.activeHydratedTeams$
  ]).pipe(
    debounceTime(200),
    map(([searchText, searchFuse, leagues,events,venues,teams]) => {
      if (!searchText) {
        return events; // show events by default
      }
      const searchResults = searchFuse.search(searchText, { limit: 10 }).map(r => r.item.searchListableValue);

      const eventResults = searchResults.filter(result =>
        events.some(event => event.getSearchListablePrimaryKey() === result.getSearchListablePrimaryKey())
      );

      let totalResults = [...eventResults];

      if (totalResults.length < 10) {
        const neededCount = 10 - totalResults.length;

        const leagueResults = searchResults.filter(result =>
          leagues.some(league => league.getSearchListablePrimaryKey() === result.getSearchListablePrimaryKey())
        ).slice(0, neededCount);

        totalResults = [...totalResults, ...leagueResults];
      }

      if (totalResults.length < 10) {
        const neededCount = 10 - totalResults.length;

        const venueResults = searchResults.filter(result =>
          venues.some(venue => venue.getSearchListablePrimaryKey() === result.getSearchListablePrimaryKey())
        ).slice(0, neededCount);

        totalResults = [...totalResults, ...venueResults];
      }

      if (totalResults.length < 10) {
        const neededCount = 10 - totalResults.length;

        const teamResults = searchResults.filter(result =>
          teams.some(team => team.getSearchListablePrimaryKey() === result.getSearchListablePrimaryKey())
        ).slice(0, neededCount);

        totalResults = [...totalResults, ...teamResults];
      }

      return totalResults;
    })
  );

  constructor(private domainModel: SearchDomainModel, private resourceDomainModel: ResourceDomainModel) {
    super();
    this.init();
  }

  init() {
    super.init();
  }
}

