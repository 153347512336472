import {Injectable} from '@angular/core';
import { BaseViewModel } from 'src/app/models/base/base-view-model';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {Program} from '../../../../models/program/program';
import {debounceTime, delay, filter, map, switchMap, tap} from 'rxjs/operators';
import {ProgramDomainModel} from '../../../../domainModels/program-domain-model';
import {ProgramRowType} from './program-row.component';
import {Cardable} from '../../../../models/protocols/cardable';
import {DateUtils} from '../../../../utils/date-utils';
import {Show} from '../../../../models/program/show';
import {CardBreakpoints} from '../../../../models/shared/card-breakpoints';
import {CardRowStyle} from '../../../shared/components/card-row/card-row.component';
import {ContentQuery} from '../../../../models/program/content-query';

@Injectable()
export class ProgramRowViewModel extends BaseViewModel {

  programRowType$ = new BehaviorSubject<ProgramRowType>(null);
  loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false);
  programContentQuery$ = new BehaviorSubject<ContentQuery>(null);
  loadNextPage$ = new BehaviorSubject<void>(null);
  fetchingMoreData = true;
  noResult$ = new BehaviorSubject<boolean>(false);

  cards$ = new BehaviorSubject<Cardable[]>(null);

  private clearCardsOnQueryChange = this.programContentQuery$
    .notNull()
    .subscribe(() => {
      this.cards$.next(null);
      this.fetchingMoreData = true;
    }).addTo(this.subscriptions);

  private fetchCards = combineLatest([this.programRowType$, this.programContentQuery$, this.loadNextPage$]).pipe(
    debounceTime(100),
    filter(([programRowType, contentQuery]) => programRowType !== null && !!contentQuery && !!this.fetchingMoreData),
    switchMap(([programRowType, contentQuery]) => {
      let fetchObs: Observable<Cardable[]>;
      this.loadingOpts.isLoading = true;
      switch (programRowType) {
        case ProgramRowType.Live:
          fetchObs = this.programDomainModel.getLiveProgramsPage(contentQuery);
          break;
        case ProgramRowType.Upcoming:
          fetchObs = this.programDomainModel.getUpcomingProgramsPage(contentQuery);
          break;
        case ProgramRowType.Past:
          fetchObs = this.programDomainModel.getPastProgramsPage(contentQuery);
          break;
        case ProgramRowType.Shows:
          fetchObs = this.programDomainModel.getShowsPage(contentQuery);
          break;
      }
      return fetchObs.pipe(
        delay(1000), // reduce leading spinner flickering if content loads too fast
        tap(c => {
          this.noResult$.next(c?.length>0);
          this.loadingOpts.isLoading = false;
          if (c?.length < this.programContentQuery$.value.take) {
            this.fetchingMoreData = false;
          } else {
            this.programContentQuery$.value.skip += c?.length;
          }
        })
      );
    }),
  ).subscribe((c) => {
    const updatedCards = this.cards$.getValue() ?? [];
    updatedCards.push(...c);
    this.cards$.next(updatedCards);
  }).addTo(this.subscriptions);

  rowTitle$ = this.programRowType$.pipe(map(type => {
    switch (type) {
      case ProgramRowType.Live:
        return $localize`Live Games`;
      case ProgramRowType.Upcoming:
        return $localize`Upcoming`;
      case ProgramRowType.Past:
        return $localize`Past`;
      case ProgramRowType.Shows:
        return $localize`Shows`;
    }
  }));

  rowCTA$ = this.programRowType$.pipe(map(type => {
    if (window.location.href.split('#')[1] === '/home'){
      return null;
    }
    switch (type) {
      case ProgramRowType.Past:
        return $localize`See All Past Games`;
      default:
        return null;
    }
  }));

  cardCTA$ = this.programRowType$.pipe(map(type => {
    switch (type) {
      case ProgramRowType.Shows:
        return $localize`Watch Now`;
      default:
        return null;
    }
  }));

  cardBreakpoints$ = this.programRowType$.pipe(map(type => {
    switch (type) {
      case ProgramRowType.Shows:
        return CardBreakpoints.showsBreakpoints;
      case ProgramRowType.Upcoming:
        return CardBreakpoints.upcomingBreakpoints;
      default:
        return CardBreakpoints.defaultBreakpoints;
    }
  }));

  cardStyle$ = this.programRowType$.pipe(map(type => {
    switch (type) {
      case ProgramRowType.Upcoming:
        return CardRowStyle.textInsideCard;
      default:
        return CardRowStyle.textBelowCard;
    }
  }));

  constructor(private programDomainModel: ProgramDomainModel) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  sortCardsByStartDate(cards: Cardable[], ascending: boolean): Cardable[] {
    const sortOrderMultiplier = ascending ? -1 : 1;
    return cards.sort((a: Cardable, b: Cardable) => {
      if (a instanceof Program && b instanceof Program) {
        return DateUtils.dateIsBefore(b.startDateUtc, a.startDateUtc) * sortOrderMultiplier;
      }
      if (a instanceof Show && b instanceof Show) {
        return DateUtils.dateIsBefore(b.createdDate, a.createdDate) * sortOrderMultiplier;
      }
      return  0;
    });
  }
}
