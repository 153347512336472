<app-content-container customClass="d-flex align-items-center w-100">
  <div class="tob-bar-container">
    <button class="mobile-menu mr-3" mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon class="menu-icon">menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <ng-container *ngFor="let menuItem of navItems; let last = last;">
        <button
          (click)="navItemClicked(menuItem)"
          class="px-5 label-md"
          mat-menu-item>
          {{menuItem.title}}
        </button>
        <hr *ngIf="!last" class="my-1">
      </ng-container>
    </mat-menu>
    <img (click)="navigateToRoot()" [src]="'assets/HTN Logos/png/HTN_White_Trimmed.png'" alt="logo"
         class="nav-logo">
    <div class="top-nav-item-container"
         *ngFor="let n of navItems"
         [class.hide-on-mobile]="n.hideOnMobile"
         (click)="navItemClicked(n)">
      <div class="top-nav-item"
           *ngIf="n.identifier !== 'profile'"
           [class.mb-1]="!!n.title"
           [class.active]="isNavItemActive(n)">
        <ng-container *ngIf="n.title && n.identifier !== 'search'">{{n.title}}</ng-container>
        <ng-container *ngIf="n.iconSrc">
          <img [src]="n.iconSrc" alt="">
        </ng-container>
      </div>
      <div class="top-nav-item"
           *ngIf="n.identifier === 'profile' && (viewModel.isSignedIn$|async)"
           [class.top-nav-item]="n.identifier !== 'profile'"
           [class.account-photo-sm]="n.identifier === 'profile'"
           [class.mb-1]="!!n.title"
           [class.no-img]="!(viewModel.userImageSrc$|async)"
           [class.active]="isNavItemActive(n)">
        <img alt=""
             *ngIf="n.identifier === 'profile' && (viewModel.userImageSrc$|async) && (viewModel.isSignedIn$|async)"
             [src]="viewModel.userImageSrc$|async">
        <ng-container *ngIf="n.title && n.identifier !== 'profile'">{{n.title}}</ng-container>
        <ng-container *ngIf="n.iconSrc">
          <img [src]="n.iconSrc" alt="">
        </ng-container>
      </div>
      <button class="custom-button secondary-button"
              *ngIf="n.identifier === 'profile' && !(viewModel.isSignedIn$|async)">
        Sign In
      </button>
    </div>
  </div>
</app-content-container>
