<div class="mt-16px bottom-bar">
  <app-content-container class="flex-grow-1" customClass="d-flex align-items-center w-100">
    <div class="footer-container">
      <img class="logo" alt="home team logo"
           (click)="logoClicked()"
           src="assets/HTN Sports/png/HTN Sport_White.png">
      <a class="px-4 px-lg-5 bottom-nav-item"
         (click)="faqClicked()">FAQ</a>
      <a class="px-4 px-lg-5 bottom-nav-item"
         (click)="helpClicked()">Help</a>
      <a class="px-4 px-lg-5 bottom-nav-item"
         (click)="touClicked()">Terms of Use</a>
      <a class="px-4 px-lg-5 bottom-nav-item mr-auto"
         (click)="privacyClicked()">Privacy</a>
      <div class="social-button">
        <img (click)="twitterClicked()" alt="twitter icon" src="assets/footer-icons/twitter/twitter.svg">
      </div>
      <div class="social-button">
        <img (click)="youtubeClicked()" alt="youtube icon" src="assets/footer-icons/youtube/youtube.svg">
      </div>
      <div class="social-button">
        <img (click)="instagramClicked()" alt="instagram icon" src="assets/footer-icons/instagram/instagram.svg">
      </div>
      <div class="social-button">
        <img (click)="linkedinClicked()" alt="linkedin icon" src="assets/footer-icons/linkedin/linkedin.svg">
      </div>
      <div class="social-button">
        <img (click)="tikTokClicked()" alt="tiktok icon" src="assets/footer-icons/tiktok/tiktok.svg">
      </div>

      <div class="trademark">
        &copy; 2023 Home Team Live
      </div>
    </div>
  </app-content-container>
</div>


