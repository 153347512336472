<ng-container *ngIf="(viewModel.isSignedIn$|async) && (viewModel.hydratedEvent$|async) && (viewModel.showSubscribeBanner$|async)">
  <div class="banner-container">
    <app-content-container [unsetMinHeight]="true">
      <div class="row">
        <div class="col-12 col-lg-8">
          <div class="title-md mb-4" i18n>
            Access premium broadcasts with event subscriptions.
          </div>
          <div class="paragraph-xl" i18n>
            Choose how you want to use HTN Sports with our variety of subscription options.
          </div>
          <button class="custom-button preferred-button banner-button"
                  (click)="viewModel.subscribeClicked()">Subscribe to
            the {{(viewModel.hydratedEvent$|async).abbreviation}}
          </button>
        </div>
      </div>
    </app-content-container>
  </div>
  <app-content-container [unsetMinHeight]="true">
    <app-banner-advertisement-row [bannerAdvertisements]="viewModel.eventBannerAds$|async">
    </app-banner-advertisement-row>
  </app-content-container>
</ng-container>
